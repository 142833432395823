<template>
  <el-select
    :value="selected"
    multiple
    collapse-tags
    @input="dispatch"
    :placeholder="placeholder || $t('message.product')"
    :size="size"
    class="w-100"
    filterable 
    clearable
  >
    <el-option
      v-for="(product, index) in products"
      :key="'product-' + index"
      :label="product.name"
      :value="product.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    product_ids: {
      default: Array,
    },
  },
  watch: {
    product_ids: {
      handler: function () {
        this.selected = this.product_ids;
      },
      immediate: true,
    },
  },
  data() {
    return {
        selected: [],
    };
  },

  mounted() {
    if (this.products && this.products.length === 0) this.updateInventory();
  },
  computed: {
    ...mapGetters({
      products: "products/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "products/inventory",
    }),
    
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>