<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.edit_m", {
                m: $t("message.package"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item
                        prop="name"
                        :label="$t('message.name')"
                        >
                          <el-input
                            :placeholder="$t('message.name')"
                            v-model="form.name"
                            size="medium"
                          ></el-input>
                      </el-form-item>  
                    </el-col>
                    <el-col :span="24">
                      <el-form-item
                        prop="quantity"
                        :label="$t('message.quantity')"
                        >
                          <el-input-number size="medium" v-model="form.quantity" :precision="2" :step="1" :min="1"></el-input-number>
                      </el-form-item>  
                    </el-col>

                    <el-col :span="24">
                      <el-form-item
                        prop="products"
                        :label="$t('message.products')"
                        >
                          <select-products
                              ref="products"
                              v-model="form.products"
                              :product_ids="form.products"
                              :size="'medium'"
                              :placeholder="$t('message.products')"
                            >
                          </select-products>
                      </el-form-item>  
                    </el-col>

                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import selectProducts from "@/components/multiSelects/multi-select-products";

export default {
    mixins: [form, drawer, show],
    name: "package",
    components: {
        selectProducts,
    },
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "packages/rules",
            model: "packages/model",
            columns: "packages/columns",
        }),
    },
    methods: {
        ...mapActions({
            update: "packages/update",
            show: "packages/show",
        }),
        changepackage(val){
          this.form.name = val;
        },
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (res.status == 200) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
